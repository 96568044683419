
// Libraries
import * as React from 'react'
import { graphql } from 'gatsby'

// Hooks
import { useState } from 'react'
import useIsMobile from '../hooks/useIsMobile'

// Components
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faMinus } from '@fortawesome/pro-solid-svg-icons'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import Seo from "../components/seo";

const FaqsPage = ({ data }) => {
	const { faqs, categories } = data?.contentManagement?.content
	const [selectedCategory, setSelectedCategory] = useState(categories[0].name)
	const [orderedCategories, setOrderedCategories] = useState(categories)
	const [selectIsOpen, setSelectIsOpen] = useState(false)
	const isMobile = useIsMobile()

	const faqsByCategory = categories.map(category => {
		return {
			name: category.name,
			faqs: faqs.filter(faq => faq.category === category.name)
		}
	})

	const accordionSettings = {
		allowZeroExpanded: true,
	}

	const questions = faqsByCategory.find(category => category.name === selectedCategory).faqs

	return (
		<Layout className="nav-blue-all">
			<Seo title="Frequently Asked Questions | Concerts for Carers"/>
			<section className="generic-template">
				<div className="container generic-template--parent">
					<div className="faqs container generic-template--container">
						<h1 className="generic-template__title">FAQs</h1>

						<div className={ `faqs__category-select ${selectIsOpen && "faqs__category-select--open"}` }>
							<ul>
								{ (isMobile ? orderedCategories : categories).map((category, index) => (
									<li key={ index } className={ category.name === selectedCategory && "active" }>
										<button onClick={ () => updateSelectedCategory(category.name) }>{ category.name }</button>
									</li>
								)) }
							</ul>

							<button className="faqs__button" onClick={ toggleSelect }>
								<FontAwesomeIcon icon={ faAngleDown } />
							</button>
						</div>

						<Accordion { ...accordionSettings }>
							{ questions.map((question, index) => (
								<AccordionItem className="accordion-item" key={ index }>
									<AccordionItemHeading className="accordion-item__heading">
										<AccordionItemButton className="accordion-item__button">
											<AccordionItemState>
												{ (state) => {
													return (
														<div>
															{ state.expanded ? <FontAwesomeIcon icon={ faMinus } /> : <FontAwesomeIcon icon={ faPlus } /> }
														</div>
													)
												}
												}
											</AccordionItemState>
											<h2>{ question.question }</h2>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<div dangerouslySetInnerHTML={ { __html: question.answer } } />
									</AccordionItemPanel>
								</AccordionItem>
							)) }
						</Accordion>
					</div>
				</div>
			</section>
		</Layout>
	)

	function toggleSelect() {
		setSelectIsOpen(!selectIsOpen)
	}

	function updateSelectedCategory(category) {
		setSelectedCategory(category)
		setSelectIsOpen(false)

		// Reorder categories so the seleceted one is first
		const newCategories = orderedCategories.filter(cat => cat.name !== category)
		newCategories.unshift(orderedCategories.find(cat => cat.name === category))
		setOrderedCategories(newCategories)
	}
}

export default FaqsPage

export const query = graphql`
{
	contentManagement(slug: {eq: "faqs"}) {
		id
		content {
			faqs {
				answer
				category
				question
			}
			categories {
				name
			}
		}
	}
}
`
