import React from "react";

// Hooks
import { useEffect } from "react";

const useIsMobile = () => {
	const isBrowser = typeof window !== 'undefined'
	const [isMobile, setIsMobile] = React.useState(isBrowser && window.innerWidth < 851);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(isBrowser && window.innerWidth < 851);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isMobile;
};

export default useIsMobile;
